import React from "react";
import { Container } from "../../style";
import "../../components/Testimonial/testimonial.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useTranslation } from "react-i18next";
import maja from "../../assets/Img/clients/new26.jpeg";
import miha from "../../assets/Img/clients/miha5.jpg";
import filip from "../../assets/Img/clients/filipBend.png";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

export default function Testimonial() {
  const { t } = useTranslation();
  return (
    <div>
      <Container>
        <h2 style={{ marginTop: 0 }} className="sub-title">
          {t("feedback.title")}
        </h2>
        <Carousel
          infinite={true}
          autoPlaySpeed={5000}
          autoPlay={true}
          responsive={responsive}
          showDots
        >
          <div className="testimonial-slider">
            <div className="title-testi">
              <div className="img-testi">
                <img src={filip} alt="Maja" />
              </div>
              <div>
                <h4>Filip Mitrović Bend</h4>
              </div>
            </div>
            <p dangerouslySetInnerHTML={{ __html: t("feedback.text3") }} />
          </div>

          <div className="testimonial-slider">
            <div className="title-testi">
              <div className="img-testi">
                <img src={miha} alt="Mihailo" />
              </div>
              <div>
                <h4>Mihailo Zivanovic</h4>
              </div>
            </div>
            <p>
              {t("feedback.text2")}
            </p>
          </div>

          <div className="testimonial-slider">
            <div className="title-testi">
              <div className="img-testi">
                <img src={maja} alt="Maja" />
              </div>
              <div>
                <h4>Maja Lazovic</h4>
              </div>
            </div>
            <p>
              {t("feedback.text1")}
            </p>
          </div>
        </Carousel>
      </Container>
    </div>
  );
}
