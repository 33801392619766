import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";
import loadable from "@loadable/component";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./pages/About/About";

import AOS from "aos";
import "aos/dist/aos.css";
import ContactInfoChat from "./components/ContactInfoChat";

const Navbar = loadable(() => import("./components/Navbar/Navbar"));
const Home = loadable(() => import("./pages/Home/Home"));
// const Blog = loadable(() => import("./pages/Blog/Blog"));
const SingleBlog = loadable(() => import("./pages/Blog/SingleBlog"));
const Portfolio = loadable(() => import("./pages/Portfolio"));
const Faqs = loadable(() => import("./pages/Faqs"));
const Seo = loadable(() => import("./pages/Seo"));
const Contact = loadable(() => import("./pages/Contact/Contact"));
const Footer = loadable(() => import("./components/Footer/Footer"));
const Cookies = loadable(() => import("./components/Cookies/Cookies"));
const NotFound = loadable(() => import("./pages/NotFound/NotFound"));
const Policy = loadable(() => import("./pages/Privacy/Privacy"));
const WebDevelopment = loadable(() => import("./pages/WebsiteDevelopment"));
const EComerceWebsite = loadable(() => import("./pages/E-comerceWebsite"));
const MobileAppDevelopment = loadable(() => import("./pages/MobileAppDevelopment"));
const SocialMediaMarketing = loadable(() => import("./pages/SocialMediaMarketing"));
const PPC = loadable(() => import("./pages/Ppc"));
const WebDesign = loadable(() => import("./pages/WebDesign"));
const Redesign = loadable(() => import("./pages/Redesign"));
const DesignLogo = loadable(() => import("./pages/DesignLogo"));

function App() {

  const { t } = useTranslation();
  document.title = t("title");

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <>
    <Router>
        <Navbar />
        <div className="App">
            <Routes>
              <Route path="/" element={<Home/>}/>
              <Route path="/portfolio" element={<Portfolio/>}/>
              <Route path="/o-nama" element={<About/>}/>
              {/* <Route path="/blog" element={<Blog/>}/> */}
              <Route path="/najcesce-postavljena-pitanja" element={<Faqs/>}/>
              <Route path="/blog/:id" element={<SingleBlog/>} />
              <Route path="/seo" element={<Seo/>}/>
              <Route path="/kontakt" element={<Contact/>}/>
              <Route path="/izrada-web-sajtova" element={<WebDevelopment/>}/>
              <Route path="/izrada-web-prodavnice" element={<EComerceWebsite/>}/>
              <Route path="/izrada-mobilnih-aplikacija" element={<MobileAppDevelopment/>}/>
              <Route path="/social-media-marketing" element={<SocialMediaMarketing/>}/>
              <Route path="/ppc" element={<PPC/>}/>
              <Route path="/web-dizajn" element={<WebDesign/>}/>
              <Route path="/redizajn-sajta" element={<Redesign/>}/>
              <Route path="/logo-dizajn" element={<DesignLogo/>}/>
              <Route path="/politika-privatnosti" element={<Policy/>}/>
              <Route path="*" element={<NotFound/>}/>
            </Routes>
            <Footer />
        </div>
      <Cookies />
      <ContactInfoChat/>
    </Router>
    </>
  );
}

export default App;
