import React, { useState } from "react";
import { LuPenSquare } from "react-icons/lu";
import { FaTimes } from "react-icons/fa";
import { BsTelephone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";

import { BsInstagram } from "react-icons/bs";
import { AiOutlineLinkedin } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";

import { GoArrowUpRight } from "react-icons/go";

export default function ContactInfoChat() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div onClick={toggleChat} className={`info-chat ${isOpen ? "open" : ""}`}>
      <div className="chat-icon">
        {isOpen ? <FaTimes /> : <LuPenSquare />}
      </div>
      {isOpen &&
        <div className="chat-window">
          <p>
            Kontaktirajte nas putem telefona, emaila ili popunite formu na sajtu
            i pratite nas na društvenim mrežama.
          </p>
          <span>Kontaktirajte nas</span>
          <div className="icon-box">
            <a className="info-icon" href="tel:+381656287390">
              <BsTelephone />
            </a>
            <a className="info-icon" href="mailto:office@gold-digital.rs">
              <AiOutlineMail />
            </a>
          </div>
          <div style={{ marginTop: 20 }}>
            <span>Zapratite nas</span>
            <div className="icon-box">
              <a
                className="info-icon"
                href="https://www.instagram.com/golddigital.rs/"
                target="_blank"
                rel="noreferrer"
              >
                <BsInstagram />
              </a>
              <a
                className="info-icon"
                href="https://www.linkedin.com/company/gold-digital-agency/"
                target="_blank"
                rel="noreferrer"
              >
                <AiOutlineLinkedin />
              </a>
              <a
                className="info-icon"
                href="https://www.facebook.com/golddigital.rs"
                rel="noreferrer"
                target="_blank"
              >
                <FaFacebookF />
              </a>
            </div>
          </div>

          <div style={{ marginTop: 30 }}>
            <a className="form-chat" href="/kontakt">
              Popunite formu <GoArrowUpRight />
            </a>
          </div>
        </div>}
    </div>
  );
}
