import styled from "styled-components";

export const Container = styled.div`
  width: 80%;
  margin: 0 auto;
  padding-top: 100px;

  .faq-title {
    font-size: 30px;
    margin-top: 50px;
    margin-bottom: 0;
    padding: 10px;
    font-weight: 500;
  }

  @media (min-width: 1400px) {
    width: 1300px;
  }
  @media (max-width: 1400px) {
    width: 90%;
  }
`;
